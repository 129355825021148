import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import useEventsBus from '../utils/eventBus'
import { SUPPORT_LOCALES, setI18nLanguage, loadLocaleMessages } from '../i18n'

export function setupRouter(i18n: any) {
  const { emit } = useEventsBus()

  const locale = i18n.mode === 'legacy' ? i18n.global.locale : i18n.global.locale.value

  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
      {
        path: '/',
        name: 'home',
        props: true,
        component: HomeView
      },
      {
        path: '/aboutUs',
        name: 'aboutUS',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/AboutUsView.vue')
      },
      {
        path: '/sportello/:id',
        name: 'sportello',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/SportelloView.vue')
      },
      {
        path: '/chi-siamo',
        name: 'chi-siamo',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/AboutUsView.vue')
      },
      {
        path: '/contatti',
        name: 'contatti',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/AboutUsView.vue')
      },
      {
        path: '/privacy-policy',
        name: 'privacy-policy',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/PrivacyPolicyView.vue')
      },
      {
        path: '/cookie-policy',
        name: 'cookie-policy',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/CookiePolicyView.vue')
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('../views/NotFound.vue')
      }
    ],
    scrollBehavior(to) {
      if (to.params.idpage) {
        return { left: 0, top: 0 }
      } else {
        return { left: 0, top: 0 }
      }
    }
  })

  return router
}
