<template>
  <section>
    <swiper
      :modules="modules"
      :loop="true"
      :loopFillGroupWithBlank="true"
      :autoplay="{
        delay: 10000,
        disableOnInteraction: false
      }"
      :pagination="{
        type: 'progressbar'
      }"
      :navigation="true"
      class="mySwiper"
    >
      <!--
      <swiper-slide>
        <div class="img-home-gallery d-flex">
          <div class="">
            <img alt="img gallery" :src="imgUrl" class="img2" />
          </div>
          <div
            class="column-dx margin-img d-flex justify-content-center align-content-center align-items-center flex-column"
          >
            <span class="title"
              >PER INFORMAZIONI LASCIA IL NUMERO<br />
              TI CHIAMIAMO NOI</span
            >
            <a
              @click="scrollToElement.scrollToElement('contactUS', 'tab')"
              role="button"
              class="mt-4 btn btn-primary button-text"
              >Contattaci</a
            >
          </div>
        </div>
      </swiper-slide>
	-->
      <swiper-slide>
        <img
          alt="img gallery"
          src="../assets/img/homeGallery/slide_1.webp"
          class="img-home-gallery"
        />
      </swiper-slide>
    </swiper>
  </section>
</template>
<script setup>
import { defineComponent, ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import useScrollToElement from '../utils/scrollToElement'
import jesiChiaravallePiuControllo from '../assets/jesi-chiaravalle-piu-controllo.jpg'
import jesiChiaravallePiuControllo480h from '../assets/jesi-chiaravalle-piu-controllo-480h.gif'

const modules = [Navigation, Pagination, Autoplay]

const scrollToElement = useScrollToElement()
// install Swiper modules

const onSwiper = () => {
  console.log(swiper)
}
const onSlideChange = () => {
  console.log('slide change')
}
const windowWidth = ref(window.innerWidth)
const imgUrl = ref(jesiChiaravallePiuControllo)

window.onresize = () => {
  windowWidth.value = window.innerWidth
  onResize(windowWidth.value)

  console.log(windowWidth.value)
}
onResize(windowWidth.value)
function onResize(r) {
  if (r > 600) {
    imgUrl.value = jesiChiaravallePiuControllo
  } else {
    imgUrl.value = jesiChiaravallePiuControllo480h
  }
}
</script>
<style>
.img-home-gallery {
  display: block;
  object-fit: none;
  height: 400px;
  width: 100%;
}

.img2 {
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.column-dx .title {
  font-size: calc(8px + 1vw);
  color: var(--bs-link-color);
  font-weight: 700;
  text-align: center;
}

.margin-img {
  margin: 0;
  padding: 0;
}

.column-dx {
  flex-shrink: 4;
}

.button-text {
  font-size: calc(6px + 0.5vw);
}

@media (min-width: 480px) {
  .img-home-gallery {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .margin-img {
    margin-right: auto;
    margin-left: auto;
  }

  .column-dx {
    flex-shrink: 1;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper .swiper-slide {
  height: auto;
}
</style>
