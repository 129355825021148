import { defineStore } from 'pinia'
import axios, { type AxiosRequestConfig } from 'axios'
const URL_BACKEND_API = import.meta.env.VITE_URL_BACKEND_API

const instance = axios.create({
  baseURL: URL_BACKEND_API,
  timeout: 3000,
  headers: {
    'Content-type': 'application/json'
  }
})

export const useCounterStore = defineStore({
  id: 'counter',
  state: () => ({
    frontoffices: [] as any,
    products: [] as any,
    messageObjects: [] as any,
    languages: [] as any,
    languagesImage: [] as any
  }),
  getters: {
    gettersfrontoffices: (state) => state.frontoffices,
    gettersProducts: (state) => state.products,
    gettersMessageObjects: (state) => state.messageObjects,
    gettersLanguages: (state) => state.languages,
    gettersLanguagesImage: (state) => state.languagesImage
  },
  actions: {
    async getFrontOffices() {
      await instance
        .get('/frontoffices')
        .then((response: any) => {
          // handle success
          this.frontoffices = response.data
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    },
    async getProducts() {
      await instance
        .get('/products')
        .then((response: any) => {
          // handle success
          this.products = response.data
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    },
    async getMessageObjects() {
      await instance
        .get('/get-message-objects')
        .then((response: any) => {
          // handle success
          this.messageObjects = response.data
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    },
    async getLanguages() {
      await instance
        .get('/get-languages')
        .then((response: any) => {
          // handle success
          this.languages = response.data
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    },
    async getLanguagesImages(pathImage: any) {
      await instance
        .get('/get-languages-images', pathImage)
        .then((response: any) => {
          // handle success
          this.languagesImage = response.data
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    }
  },
  persist: true
})
