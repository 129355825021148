<template>
  <nav class="navbar-expand-xl filter-bar navbar-light">
    <div class="container-fluid row">
      <div class="col-5 row flex-nowrap">
        <button
          class="navbar-toggler button-filter-collapse"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarFilter"
          aria-controls="navbarFilter"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <font-awesome-icon :icon="['fa', 'filter']" class="icon-filter" />
        </button>
        <slot name="filterView" class="filterView filter-View" id="filterView"> </slot>
      </div>
      <div class="collapse navbar-collapse navbar-collapse-filter col-4" id="navbarFilter">
        <ul class="navbar-nav" citySelected districtSelected regionSelected>
          <li class="nav-item nav-item-filter dropdown">
            <Multiselect
              class="dropdown-citta"
              placeholder="Città"
              :options="get('city')"
              v-model="citySelected"
              @change="onCityChange"
              :searchable="true"
              mode="tags"
              ref="citySelected_"
            >
            </Multiselect>
          </li>
          <li class="nav-item nav-item-filter dropdown">
            <Multiselect
              class="item"
              placeholder="Provincia"
              :options="get('district')"
              v-model="districtSelected"
              @change="onDistrictChange"
              :searchable="true"
              mode="tags"
              ref="districtSelected_"
            >
              <option v-for="(district, index) in get('district')" :key="index">
                {{ district }}
              </option>
            </Multiselect>
          </li>
          <li class="nav-item nav-item-filter dropdown">
            <Multiselect
              class="item align-items-center"
              placeholder="Regione"
              :options="get('region')"
              v-model="regionSelected"
              @change="onRegionChange"
              :searchable="true"
              mode="tags"
              ref="regionSelected_"
            >
              <option v-for="(region, index) in get('region')" :key="index">
                {{ region }}
              </option>
            </Multiselect>
          </li>

          <li class="nav-item nav-item-filter-button">
            <div>
              <button class="btn btn-primary" @click="reset()">Reset</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script setup lang="ts">
import { useCounterStore } from '@/stores/counter'
import { computed, ref } from 'vue'
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'
const emit = defineEmits([
  'update:citySelected',
  'update:districtSelected',
  'update:regionSelected',
  'update:reset'
])
const props = defineProps(['citySelected', 'districtSelected', 'regionSelected', 'frontoffices'])

const citySelected: any = ref()
const districtSelected: any = ref()
const regionSelected: any = ref()

const citySelected_: any = ref()
const districtSelected_: any = ref()
const regionSelected_: any = ref()

//let reset: any = ref();
const store = useCounterStore()

const frontoffices = computed(() => props.frontoffices)
const frontofficesFull: any = store.gettersfrontoffices
function get(a: any) {
  const result: any[] = [],
    seen = new Set()
  outer: for (let index = 0; index < frontofficesFull.length; index++) {
    const value = frontofficesFull[index][a]

    if (seen.has(value)) continue outer
    seen.add(value)
    result.push(value)
  }
  return result
}

function search(a: any, c: any) {
  const length = frontoffices.value.length,
    result: any[] = [],
    seen = new Set()
  outer: for (let index = 0; index < length; index++) {
    let value
    if (c != null) {
      if (frontoffices.value[index][a] == c) {
        value = frontoffices.value[index][a]
      }
    } else {
      value = frontoffices.value[index][a]
    }
    if (seen.has(value)) continue outer
    seen.add(value)
    result.push(value)
  }
  return result
}

function reset() {
  emit('update:citySelected', '')
  emit('update:districtSelected', '')
  emit('update:regionSelected', '')
  citySelected_.value.clear()
  districtSelected_.value.clear()
  regionSelected_.value.clear()
  emit('update:reset')
}

function onCityChange(value: any) {
  emit('update:citySelected', value)
}

function onDistrictChange(value: any) {
  emit('update:districtSelected', value)
}
function onRegionChange(value: any) {
  emit('update:regionSelected', value)
}
</script>
<style lang="scss">
.nav-item.nav-item-filter {
  --vs-dropdown-min-width: 160px;
  min-width: 160px;
  margin-bottom: 1rem;
}
:root {
  --vs-dropdown-min-width: 160px;
}
.item {
  width: 236px;
}
.dropdown-citta {
  width: 260px;
}
.filter-bar .row {
  --bs-gutter-x: 0rem;
  --bs-gutter-y: 0;
  margin-bottom: 1rem;
}

.navbar-collapse.navbar-collapse-filter {
  background-color: aliceblue;
  border-color: black;
  padding: 2rem 2rem 2rem;
  //padding-right: 10rem;
}

.navbar-nav .nav-item.nav-item-filter {
  //margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
}
.navbar-nav .nav-item.nav-item-filter-button {
  //margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-View {
  flex: 0 0 auto;
  width: 100px;
}
.button-filter-collapse {
  flex: 0 0 auto;
  width: 50px;
  margin-right: 1rem;
}
@media screen and (min-width: 573px) {
  .nav-item.nav-item-filter {
    --vs-dropdown-min-width: 160px;
    min-width: 160px;
    margin-bottom: 1rem;
  }
  .nav-item.nav-item-filter-button {
    margin-bottom: 1rem;
  }
  .navbar-collapse.navbar-collapse-filter {
    background-color: aliceblue;
    border-color: black;
    padding: 2rem 7rem 2rem;
    //padding-right: 10rem;
  }
}
@media screen and (min-width: 763px) {
  .nav-item.nav-item-filter {
    --vs-dropdown-min-width: 160px;
    min-width: 160px;
    margin-bottom: 1rem;
  }
  .nav-item.nav-item-filter-button {
    margin-bottom: 1rem;
  }
  .navbar-collapse.navbar-collapse-filter {
    background-color: aliceblue;
    border-color: black;
    padding: 2rem 12rem 2rem;
    //padding-right: 10rem;
  }
}
@media screen and (min-width: 1200px) {
  .nav-item.nav-item-filter {
    --vs-dropdown-min-width: 160px;
    min-width: 160px;
    max-width: 280px;
    margin-right: 1rem;
  }
  .nav-item.nav-item-button {
    max-width: 280px;
    margin-right: 1rem;
  }
  .navbar-collapse.navbar-collapse-filter {
    background-color: transparent;
    border-color: black;
    padding: 0;
    //padding-right: 10rem;
  }
  .navbar-expand-xl .navbar-collapse.navbar-collapse-filter {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-collapse-filter {
    justify-content: end;
  }
}
.filter-bar .navbar-light .navbar-toggler-icon {
  background-image: none;
}
.filter-bar .icon-filter {
  color: black;
  font-size: 16px;
}
</style>
