import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import { supportedLocalesInclude } from './i18n/supported-locales'
import getBrowserLocale from './i18n/get-browser-locale'

export const SUPPORT_LOCALES = ['en', 'ja']

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true })
  return import.meta.env.VUE_APP_I18N_LOCALE || 'en'
  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale
  } else {
    return import.meta.env.VUE_APP_I18N_LOCALE || 'en'
  }
}

const startingLocale = getStartingLocale()

export function setupI18n(options: any = { startingLocale }) {
  const i18n = createI18n(options)
  setI18nLanguage(i18n, options.locale)
  return i18n
}

export function setI18nLanguage(i18n: any, locale: any) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  ;(document.querySelector('html') as unknown as HTMLInputElement).setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n: any, locale: any) {
  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
  )

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}
