import axios from 'axios'
import type { AxiosInstance } from 'axios'
const URL_BACKEND_API = import.meta.env.VITE_URL_BACKEND_API

const apiClient: AxiosInstance = axios.create({
  baseURL: URL_BACKEND_API,
  timeout: 3000
})

export default apiClient
