<template>
  <div :class="tabClass">
    <div :class="tabPanelClass">
      <div
        v-show="isActive"
        :aria-hidden="!isActive"
        :class="tabContentClass"
        :id="computedId"
        role="tabpanel"
        ref="tab"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { inject, watch, ref, onBeforeMount, onBeforeUnmount, defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'TabComponent',
  props: {
    tabContentClass: {
      type: String,
      default: 'tab-content'
    },
    tabClass: {
      type: String,
      default: 'tab'
    },
    tabPanelClass: {
      type: String,
      default: 'tab-panel'
    },
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const isActive = ref(false)

    const tabsProvider: any = inject('tabsProvider')
    const addTab: any = inject('addTab')
    const updateTab: any = inject('updateTab')
    const deleteTab: any = inject('deleteTab')

    const header = props.prefix + props.name + props.suffix
    const computedId = computed(() => {
      return props.id ? props.id : props.name.toLowerCase().replace(/ /g, '-')
    })
    const hash = '#' + (!props.isDisabled ? computedId.value : '')

    watch(
      () => tabsProvider.activeTabHash,
      () => {
        isActive.value = hash === tabsProvider.activeTabHash
      }
    )

    watch(
      () => Object.assign({}, props),
      () => {
        updateTab(computedId.value, {
          name: props.name,
          header: header,
          isDisabled: props.isDisabled,
          hash: hash,
          index: tabsProvider.tabs.length,
          computedId: computedId.value
        })
      }
    )

    onBeforeMount(() => {
      addTab({
        name: props.name,
        header: header,
        isDisabled: props.isDisabled,
        hash: hash,
        index: tabsProvider.tabs.length,
        computedId: computedId.value
      })
    })

    onBeforeUnmount(() => {
      deleteTab(computedId.value)
    })

    return {
      header,
      computedId,
      hash,
      isActive
    }
  }
})
</script>
