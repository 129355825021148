<template>
  <div class="" v-html="day"></div>
</template>
<script lang="ts">
/* eslint-disable vue/no-setup-props-destructure */

import { defineComponent } from 'vue'
export default defineComponent({
  components: {},
  props: ['frontoffice'],
  setup(props) {
    const date = new Date()
    const options: any = { weekday: 'long' }
    let day
    let day1
    const a = date.toLocaleDateString('en-US', options)

    if (a == 'Monday' && props.frontoffice.monday_afternoon != 'close') {
      day1 =
        props.frontoffice.monday_afternoon_start + ' - ' + props.frontoffice.monday_afternoon_end
    } else if (a == 'Tuesday' && props.frontoffice.tuesday_afternoon != 'close') {
      day1 =
        props.frontoffice.tuesday_afternoon_start + ' - ' + props.frontoffice.tuesday_afternoon_end
    } else if (a == 'Wednesday' && props.frontoffice.wednesday_afternoon != 'close') {
      day1 =
        props.frontoffice.wednesday_afternoon_start +
        ' - ' +
        props.frontoffice.wednesday_afternoon_end
    } else if (a == 'Thursday' && props.frontoffice.thursday_afternoon != 'close') {
      day1 =
        props.frontoffice.thursday_afternoon_start +
        ' - ' +
        props.frontoffice.thursday_afternoon_end
    } else if (a == 'Friday' && props.frontoffice.friday_afternoon != 'close') {
      day1 =
        props.frontoffice.friday_afternoon_start + ' - ' + props.frontoffice.friday_afternoon_end
    } else if (a == 'Saturday' && props.frontoffice.saturday_afternoon != 'close') {
      day1 =
        props.frontoffice.saturday_afternoon_start +
        ' - ' +
        props.frontoffice.saturday_afternoon_end
    } else {
      day1 = 'CHIUSO'
    }

    if (a == 'Monday' && props.frontoffice.monday_morning != 'close') {
      day = props.frontoffice.monday_morning_start + ' - ' + props.frontoffice.monday_morning_end
    } else if (a == 'Tuesday' && props.frontoffice.tuesday_morning != 'close') {
      day = props.frontoffice.tuesday_morning_start + ' - ' + props.frontoffice.tuesday_morning_end
    } else if (a == 'Wednesday' && props.frontoffice.wednesday_morning != 'close') {
      day =
        props.frontoffice.wednesday_morning_start + ' - ' + props.frontoffice.wednesday_morning_end
    } else if (a == 'Thursday' && props.frontoffice.thursday_morning != 'close') {
      day =
        props.frontoffice.thursday_morning_start + ' - ' + props.frontoffice.thursday_morning_end
    } else if (a == 'Friday' && props.frontoffice.friday_morning != 'close') {
      day = props.frontoffice.friday_morning_start + ' - ' + props.frontoffice.friday_morning_end
    } else if (a == 'Saturday' && props.frontoffice.saturday_morning != 'close') {
      day =
        props.frontoffice.saturday_morning_start + ' - ' + props.frontoffice.saturday_morning_end
    } else {
      day = 'CHIUSO'
    }

    if (day == 'CHIUSO' && day1 == 'CHIUSO') {
      day = "<font color='#cb9d13'>OGGI CHIUSO</font>"
    } else if (day == 'CHIUSO' && day1 != 'CHIUSO') {
      day = "<font color='#cb9d13'>OGGI APERTO </font><br><font color='green'>" + day1 + '</font>'
    } else if (day != 'CHIUSO' && day1 == 'CHIUSO') {
      day = "<font color='#cb9d13'>OGGI APERTO </font><br><font color='green'>" + day + '</font>'
    } else {
      day =
        "<p><font color='#cb9d13'>OGGI APERTO </font><br><font color='green'>" +
        "<font color='#cb9d13'>DALLE </font><font color='green'>" +
        day +
        " <font color='#cb9d13'>ALLE</font><font color='green'> " +
        day1 +
        '</font></p>'
    }

    return { day }
  }
})
</script>
