<template>
  <div class="accordion accordion-flush" :id="accordionId">
    <div class="accordion-item">
      <button
        :class="classButton"
        type="button"
        data-bs-toggle="collapse"
        :data-bs-target="'#' + accordionCollapseId"
        :aria-expanded="alwaysopen"
        :aria-controls="accordionCollapseId"
      >
        <slot name="accordion-header" class="accordion-header" id="accordionCollapseId"> </slot>
      </button>

      <div
        :id="accordionCollapseId"
        :class="classContent"
        :aria-labelledby="accordionCollapseId"
        :data-bs-parent="'#' + accordionId"
      >
        <div class="accordion-body">
          <slot name="accordion-body">
            Placeholder content for this accordion, which is intended to demonstrate the
            <code>.accordion-flush</code> class. This is the first item's accordion body.
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    alwaysopen: {
      type: Boolean,
      default: false
    },
    classButton: {
      type: String,
      default: 'accordion-button collapsed'
    },
    classContent: {
      type: String,
      default: 'accordion-collapse collapse'
    },
    accordionCollapseId: {
      type: String,
      default: 'flush-collapseOne'
    },
    accordionId: {
      type: String,
      default: 'accordionFlushExample'
    }
  }
})
</script>
