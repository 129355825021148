<template>
  <section id="gallery-sportello">
    <div class="row justify-content-sm-center">
      <div class="product_wrapper flex-wrap justify-content-sm-center">
        <img alt="img" id="img-wrapper" :src="img" class="img-wrapper img-fluid" />
      </div>
    </div>

    <!--individual products in product view-->
    <div id="gal1" class="imgContainer">
      <a v-for="image in images" :key="image.id" class="" @click="setImg(image)">
        <img alt="img" id="img_01" :src="urlServer + image.path + image.name" class="img-fluid" />
      </a>
    </div>
  </section>
</template>
<script setup lang="ts">
// import required modules

import { ref, reactive } from 'vue'
import { getCurrentInstance } from 'vue'
const props = defineProps(['images'])
const img = ref('')
const images = reactive(props.images)
const app: any = getCurrentInstance()
const urlServer = app.appContext.config.globalProperties.$urlServer

const setImg = (imgSelected: any) => {
  img.value = urlServer + imgSelected.path + imgSelected.name
}

setImg(images[0])
</script>
<style lang="scss" scoped>
.imgContainer {
  display: flex;
  flex-direction: row;
}
#gal1 img {
  width: 94px;
  float: left;
  padding: 2px;
}
@media (min-width: 1010px) {
  #gallery-sportello {
    width: 374px;
  }
  .imgContainer {
    flex-wrap: wrap;
  }
}
.img-wrapper {
  width: 377.33px;
  height: 245.91px;
}
.product_wrapper {
  margin-top: 10px;
  width: 100%;
}
#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family:
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body {
  background: #000;
  color: #000;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
