<template>
  <section class="section-lg">
    <div class="row row-50 justify-content-md-center">
      <div class="col-md-10 col-lg-6 padding-margin-0">
        <slot name="col-sx"> </slot>
      </div>
      <div class="col-md-10 col-lg-6 padding-margin-0">
        <slot name="col-dx"> </slot>
      </div>
    </div>
  </section>
</template>

<style scoped>
.img {
  position: relative;
}
.padding-margin-0 {
  padding: 0;
  margin: 0;
}
</style>
