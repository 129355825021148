<template>
  <div :class="wrapperClass + ' ' + direction">
    <ul role="tablist" :class="navClass + ' ' + position">
      <li
        v-for="(tab, i) in state.tabs"
        :key="i"
        :class="[
          navItemClass,
          tab.isDisabled ? navItemDisabledClass : '',
          tab.isActive ? navItemActiveClass : ''
        ]"
        role="presentation"
        :aria-selected="tab.isActive"
      >
        <a
          v-html="tab.header"
          :aria-controls="tab.hash"
          :aria-selected="tab.isActive"
          @click="selectTab(tab.hash, $event)"
          :href="tab.hash"
          :class="[
            navItemLinkClass,
            tab.isDisabled ? navItemLinkDisabledClass : '',
            tab.isActive ? navItemLinkActiveClass : ''
          ]"
          role="tab"
        ></a>
      </li>
    </ul>
    <div :class="panelsWrapperClass">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import expiringStorage from '../Tab/expiringStorage'
import { reactive, provide, onMounted, toRefs, defineComponent, inject, watch } from 'vue'
import useEventsBus from '../../utils/eventBus.js'

const props = defineProps({
  cacheLifetime: {
    default: 5
  },
  options: {
    type: Object,
    required: false,
    default: () => ({
      useUrlFragment: true,
      defaultTabHash: null
    })
  },
  wrapperClass: {
    type: String,
    default: 'tabs'
  },
  panelsWrapperClass: {
    type: String,
    default: 'tabs-panel'
  },
  navClass: {
    type: String,
    default: 'tab-list'
  },
  position: {
    type: String,
    default: ''
  },
  navItemClass: {
    type: String,
    default: 'nav-item'
  },
  navItemDisabledClass: {
    type: String,
    default: 'is-disabled'
  },
  navItemActiveClass: {
    type: String,
    default: 'is-active'
  },
  navItemLinkClass: {
    type: String,
    default: 'text-decoration-none tab-list__item'
  },
  navItemLinkActiveClass: {
    type: String,
    default: 'is-active'
  },
  navItemLinkDisabledClass: {
    type: String,
    default: 'is-disabled'
  },
  direction: {
    type: String,
    default: ' horizontal '
  }
})

const emit = defineEmits(['changed', 'clicked'])
const { bus } = useEventsBus()

watch(
  () => bus.value.get('selectTab'),
  (val) => {
    // destruct the parameters
    const [selectingTab] = val ?? []
    selectTab(selectingTab, '')
  }
)

const state = reactive({
  activeTabHash: '',
  lastActiveTabHash: '',
  tabs: [] as any
})

provide('tabsProvider', state)

provide('addTab', (tab: never) => {
  return state.tabs.push(tab)
})

provide('updateTab', (computedId: never, data: never) => {
  const tabIndex = state.tabs.findIndex((tab: any) => (tab as any).computedId === computedId)

  state.tabs[tabIndex] = data
})

provide('deleteTab', (computedId: never) => {
  const tabIndex = state.tabs.findIndex((tab: any) => (tab as any).computedId === computedId)

  state.tabs.splice(tabIndex, 1)
})

const storageKey = `vue-tabs-component.cache.${window.location.host}${window.location.pathname}`

const selectTab = (selectedTabHash: any, event: any) => {
  if (event && !props.options.useUrlFragment) {
    event.preventDefault()
  }

  const selectedTab: any = findTab(selectedTabHash)

  if (!selectedTab) {
    return
  }

  if (event && selectedTab.isDisabled) {
    event.preventDefault()
    return
  }

  if (state.lastActiveTabHash === selectedTab.hash) {
    emit('clicked', { tab: selectedTab })
    return
  }

  state.tabs.forEach((tab: any) => {
    tab.isActive = tab.hash === selectedTab.hash
  })

  emit('changed', { tab: selectedTab })

  state.lastActiveTabHash = state.activeTabHash = selectedTab.hash

  expiringStorage.set(storageKey, selectedTab.hash, props.cacheLifetime)
}

const findTab = (hash: any) => {
  return state.tabs.find((tab: any) => (tab as any).hash === hash)
}

onMounted(() => {
  if (!state.tabs.length) {
    return
  }

  window.addEventListener('hashchange', () => selectTab(window.location.hash, 'a'))

  if (findTab(window.location.hash)) {
    selectTab(window.location.hash, '')
    return
  }

  const previousSelectedTabHash = expiringStorage.get(storageKey)

  if (findTab(previousSelectedTabHash)) {
    selectTab(previousSelectedTabHash, '')
    return
  }

  if (props.options.defaultTabHash && findTab('#' + props.options.defaultTabHash)) {
    selectTab('#' + props.options.defaultTabHash, '')
    return
  }

  selectTab(state.tabs[0].hash, '')
})
</script>
<style lang="scss" rel="stylesheet/scss">
:root {
  --primary-color: #4313aa;
  --border-color: #e2e2e2;
  --disabled-text-color: #999;
}
.tabs {
  display: grid;
  grid-template-columns: 1fr;
  .tab-list {
    list-style: none;
    display: flex;
    padding-left: 0;
    border-bottom: 1px solid var(--border-color);
    &.center {
      justify-content: center;
    }
    &.end {
      justify-content: flex-end;
    }
    &__item {
      padding: 8px 10px;
      cursor: pointer;
      user-select: none;
      transition: border 0.3s ease-in-out;
      position: relative;
      bottom: -1px;
      text-transform: uppercase;
      font-size: 0.85rem;
      letter-spacing: 0.05rem;
      &:not(:first-child) {
        margin-left: 10px;
      }
      &[aria-selected='true'] {
        border-bottom: 2px solid var(--primary-color);
        font-weight: 700;
        color: var(--primary-color);
      }
      &[aria-disabled='true'] {
        cursor: not-allowed;
        color: var(--disabled-text-color);
      }
    }
  }
  &.horizontal {
    &.reverse {
      .tab-list {
        grid-row: 2;
        border: none;
        border-top: 1px solid var(--border-color);
      }
    }
  }
  &.vertical {
    grid-template-columns: auto 1fr;
    gap: 1rem;
    .tab-list {
      flex-direction: column;
      border-bottom: none;
      border-right: 1px solid var(--border-color);
      &__item {
        margin-left: 0;
        border-radius: 0;
        &[aria-selected='true'] {
          border: none;
          border-left: 2px solid var(--primary-color);
        }
      }
    }
    &.reverse {
      grid-template-columns: 1fr auto;
      .tab-list {
        grid-column: 2;
        border: none;
        border-left: 1px solid var(--border-color);
      }
      .tab {
        grid-row: 1;
        grid-column: 1;
      }
    }
  }
  a {
    display: block;
    height: 100%;
  }
}
</style>
