import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueCookieComply from 'vue-cookie-comply'
import 'vue-cookie-comply/dist/style.css'

import { faPhone, faEnvelope, faFilter, faLocationDot } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import Toast from 'vue-toastification'
import type { PluginOptions } from 'vue-toastification'

import mitt from 'mitt'

import 'vue-toastification/dist/index.css'

import http from '@/http'
import { AxiosKey } from '@/symbols'
import App from './App.vue'
import { setupRouter } from './router'
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3'

import { setupI18n } from './i18n'
const URL_BACKEND = import.meta.env.VITE_URL_BACKEND

library.add(faFacebookF, faLinkedinIn, faPhone, faEnvelope, faFilter, faLocationDot)
const app = createApp(App)
app.config.globalProperties.$urlServer = URL_BACKEND

const emitter = mitt()

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const options: PluginOptions = {
  maxToasts: 20
}

app.component('font-awesome-icon', FontAwesomeIcon)
app.config.globalProperties.emitter = emitter

app.provide(AxiosKey, http)
await app.use(pinia)

const i18n = setupI18n({
  legacy: false,
  globalInjection: true,
  locale: import.meta.env.MIX_VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: import.meta.env.MIX_VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentFallbackWarn: true,
  useLocalStorage: true,
  useDataAttrOptions: true
})

const router = setupRouter(i18n)

app.use(i18n)
app.use(router)
app.use(VueCookieComply)
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyD_bX6MlM9nEQbr3tJ0IfL72y0tap7KwoQ'
  },
  v: '3.51'
})
app.use(Toast, options)
app.use(TawkMessengerVue, {
  propertyId: '63971cdab0d6371309d4013e',
  widgetId: '1gk34hdvg'
})

router.isReady().then(() => {
  app.mount('#app')
})
