<style scoped lang="scss">
.filter-bar {
  width: 100%;
}
</style>
<template>
  <section>
    <div class="">
      <FilterSportelli
        v-model:citySelected="citySelected"
        v-model:districtSelected="districtSelected"
        v-model:regionSelected="regionSelected"
        @update:reset="reset"
        :frontoffices="frontoffices"
        class=""
      >
        <template v-slot:filterView>
          <div class="filter-View justify-content-end align-items-start">
            <select class="form-select" v-model="selectedItemsView">
              <option value="4" selected>4</option>
              <option value="8">8</option>
              <option value="20">20</option>
              <option value="32">32</option>
            </select>
          </div>
        </template>
      </FilterSportelli>
    </div>
    <div class="row row-cols-xl-5 row-cols-md-4 row-cols-sm-3 g-2 justify-content-center">
      <router-link
        :to="{ name: 'sportello', params: { id: frontoffice.id } }"
        v-for="frontoffice in frontOfficesPaginate() as any"
        :key="frontoffice.id"
        class="text-decoration-none col m-3 justify-content-center d-flex"
      >
        <CardComponent>
          <template v-slot:card-img-top v-if="frontoffice.image_front_offices[0]">
            <img
              alt="img frontoffice"
              :src="
                urlServer +
                frontoffice.image_front_offices[0].path +
                frontoffice.image_front_offices[0].name
              "
            />
          </template>
          <template v-slot:card-body
            ><p class="text-center">
              {{ frontoffice.type + ' ' + frontoffice.name }}
            </p>
            <p class="text-center pb-4 border-bottom">
              <span class="pe-2"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-telephone-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                  />
                </svg>
              </span>
              <span> {{ frontoffice.phone }}</span>
            </p>
            <p>
              <SportelloCloseOpen
                class="text-center"
                :frontoffice="frontoffice"
              ></SportelloCloseOpen>
            </p>
          </template>
        </CardComponent>
      </router-link>
    </div>
    <Paginate
      v-model="currentPage"
      :page-count="totalPageView()"
      :page-range="3"
      :click-handler="clickCallback"
      class="justify-content-center d-flex"
    >
    </Paginate>
  </section>
</template>
<script setup lang="ts">
import CardComponent from './CardComponent.vue'
import SportelloCloseOpen from './SportelloCloseOpen.vue'
import { useCounterStore } from '@/stores/counter'
import { computed, ref, getCurrentInstance, watch } from 'vue'
import FilterSportelli from './FilterSportelliComponent.vue'
import Paginate from 'vuejs-paginate-next'
import VLazyImage from 'v-lazy-image'

const app: any = getCurrentInstance()
const urlServer = app.appContext.config.globalProperties.$urlServer
const store = useCounterStore()
const citySelected: any = ref('')
const districtSelected: any = ref('')
const regionSelected: any = ref('')
const selectedItemsView: any = ref(4)
const paginate = ref(1)
const currentPage: any = ref(1)

const getFrontOfficeImg = async (iconName: any) => {
  /*
      const module = await import(
         "/../assets/img/frontoffice/" + iconName
      );
      console.log(module.default.replace(/^\/@fs/, ""));
      console.log(module.default);
      return module.default.replace(/^\/@fs/, "");*/
}
function getImageUrl(name: string) {
  return new URL('../assets/img/frontoffice/' + name, import.meta.url).href
}
const frontoffices = computed(() => {
  let frontoffices_
  if (
    citySelected.value !== null ||
    districtSelected.value !== null ||
    regionSelected.value !== null
  ) {
    if (citySelected.value.length || districtSelected.value.length || regionSelected.value.length) {
      frontoffices_ = store.gettersfrontoffices.filter((n: any) => {
        return (
          (citySelected.value == null ||
            citySelected.value.includes(n.city) == true ||
            citySelected.value.length < 1) &&
          (districtSelected.value == null ||
            districtSelected.value.includes(n.district) == true ||
            districtSelected.value.length < 1) &&
          (regionSelected.value == null ||
            regionSelected.value.includes(n.region) ||
            regionSelected.value.length < 1)
        )
      })

      trasformFrontOfficeToUpperCase(frontoffices_)
      return frontoffices_
    }
  }

  frontoffices_ = store.gettersfrontoffices
  trasformFrontOfficeToUpperCase(frontoffices_)

  return frontoffices_
})
function trasformFrontOfficeToUpperCase(array) {
  array.map(function (x) {
    x.region = x.region.toUpperCase()
    x.city = x.city.toUpperCase()
    x.district = x.district.toUpperCase()
    x.street = x.street.toUpperCase()
    return x
  })
}

function reset() {
  citySelected.value = ''
  districtSelected.value = ''
  regionSelected.value = ''
}

function clickCallback(pageNum: any) {
  //frontOfficesPaginate();
}

function frontOfficesPaginate() {
  if (currentPage.value >= frontoffices.value.length) {
    currentPage.value = totalPageView()
  } else if (totalPageView() == 1) {
    currentPage.value = 1
  } else if (currentPage.value >= totalPageView()) {
    currentPage.value = totalPageView()
  }
  const index: any = currentPage.value * selectedItemsView.value - selectedItemsView.value

  return frontoffices.value.slice(index, parseInt(index) + parseInt(selectedItemsView.value))
}

function totalPageView() {
  const length = Math.ceil(frontoffices.value.length / selectedItemsView.value)
  return length > 1 ? length : 1
}

frontOfficesPaginate()

//paginate.value = totalPageView();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
</script>
