import { useRoute, useRouter } from 'vue-router'
import { computed } from 'vue'
import useEventsBus from './eventBus.js'

export default function useScrollToElement() {
  const router = useRouter()
  const route = useRoute()
  const { emit } = useEventsBus()
  const currentRoute = computed(() => {
    return route.name
  })

  async function scrollToElement(id: string, type: string) {
    if (currentRoute.value != 'home') {
      await router
        .push({
          name: 'home'
        })
        .then(() => {
          setTimeout(async function () {
            if (type == 'tab') {
              await emit('selectTab', '#' + id)
              scrollToElement_('contacts')
            } else {
              scrollToElement_(id)
            }
          }, 300)
        })
        .catch(() => {
          // handle error
          console.log('error')
        })
    } else {
      if (type == 'tab') {
        await emit('selectTab', '#' + id)
        scrollToElement_('contacts')
      } else {
        scrollToElement_(id)
      }
    }
  }
  async function scrollToElement_(id: string) {
    if (currentRoute.value != 'home') {
      await router.push({
        name: 'home'
      })
    }
    const el = document.getElementById(id) as HTMLElement
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }

  return {
    scrollToElement
  }
}
