<template>
  <nav class="secondary-navbar">
    <div class="container-fluid d-flex flex-wrap">
      <ul class="nav list-social">
        <li class="nav-item">
          <a
            class="nav-link btn btn-square btn-link rounded-0 border-0 border-end border-secondary"
            href="https://www.facebook.com/matchancona"
            target="_blank"
            rel="noopener noreferrer"
          >
            <font-awesome-icon :icon="['fab', 'facebook-f']" class="icon" />
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link btn btn-square btn-link rounded-0 border-0 border-end border-secondary"
            href="https://www.linkedin.com/company/matchsrl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <font-awesome-icon :icon="['fab', 'linkedin-in']" class="icon" />
          </a>
        </li>
      </ul>
      <ul class="nav ms-auto">
        <li class="nav-item">
          <a class="nav-link me-2" href="mailto:">
            <font-awesome-icon :icon="['fa', 'envelope']" class="icon me-1" />
            <label class="d-none d-md-inline-block d-lg-inline-block d-xl-inline-block"
              ><a href="mailto:info@matchancona.it" class="text-white"
                >info@matchancona.it</a
              ></label
            ></a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link me-1" href="tel:">
            <font-awesome-icon :icon="['fa', 'phone']" class="icon me-1" />
            <span class="d-none d-md-inline-block d-lg-inline-block d-xl-inline-block"
              ><a href="tel:+390712832386" class="text-white">(071) 2832386</a></span
            >
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<style scoped>
.secondary-navbar {
  background-color: #f91f95 !important;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: white;
  text-decoration: none;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.nav-link {
  font-family: 'Sora', sans-serif;
  font-size: 0.9rem;
  line-height: 1.5;
  font-weight: 400;
}
.livicon {
  margin-right: 1px;
}
.list-social .nav-link {
  font-family: 'Sora', sans-serif;
  font-size: 0.9rem;
  line-height: 1.5;
  font-weight: 400;
}
.list-social .nav-link {
  display: block;
  padding: 0.5rem 12px;
  color: white;
  text-decoration: none;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.list-social .nav-link .livicon:hover {
  color: gray;
}
</style>
