<template>
  <section class="formContatti">
    <div class="row justify-content-center align-items-center">
      <div
        class="col-lg-9 col-md-9 col-sm-9 col-12 border-bottom pb-5 justify-content-center d-flex flex-column"
      >
        <Tabs
          :options="{ useUrlFragment: false }"
          direction="vertical"
          navClass="tab-list nav-tab"
          nav-item-link-class="tab-item text-decoration-none tab-list__item "
        >
          <Tab name="Contattaci" id="contactUS">
            <div class="text-center">
              <h3>Contattaci</h3>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <Suspense>
                <template #default>
                  <FormContatti v-on:update:message="getMessage" class="form"></FormContatti>
                </template>
                <template #fallback> Loading... </template>
              </Suspense>
            </div>
          </Tab>
          <Tab name="Lavora con noi" id="workWithUs">
            <div class="text-center">
              <h3>Lavora con noi</h3>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <Suspense>
                <template #default>
                  <FormLavoraConNoi
                    v-on:update:message="getMessage"
                    class="form"
                  ></FormLavoraConNoi>
                </template>
                <template #fallback> Loading... </template>
              </Suspense>
            </div>
          </Tab>

          <Tab name="Autolettura" id="selfReading">
            <div class="text-center">
              <h3>Autolettura</h3>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <Suspense>
                <template #default>
                  <FormAutolettura v-on:update:message="getMessage" class="form"></FormAutolettura>
                </template>
                <template #fallback> Loading... </template>
              </Suspense>
            </div>
          </Tab>
          <Tab name="Appuntamento" id="appointment">
            <div class="text-center">
              <h3>Richiedi Appuntamento</h3>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <Suspense>
                <template #default>
                  <FormAppuntamento
                    v-on:update:message="getMessage"
                    class="form"
                  ></FormAppuntamento>
                </template>
                <template #fallback> Loading... </template>
              </Suspense>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import { ref, defineAsyncComponent } from 'vue'
import Tabs from '../components/Tab/TabsComponent.vue'
import Tab from '../components/Tab/TabComponent.vue'
import { useToast } from 'vue-toastification'
const FormContatti = defineAsyncComponent(() => import('../components/FormContatti.vue'))
const FormLavoraConNoi = defineAsyncComponent(() => import('../components/FormLavoraConNoi.vue'))
const FormAutolettura = defineAsyncComponent(() => import('../components/FormAutolettura.vue'))
const FormAppuntamento = defineAsyncComponent(() => import('../components/FormAppuntamento.vue'))

const toast = useToast()
const message = ref()

function getMessage(value: any) {
  message.value = value
  if (value.success) {
    toast.success(value.success, {
      timeout: 2000
    })
  } else {
    toast.error('Errors', {
      timeout: 2000
    })
  }
}
</script>
<style lang="scss">
@media (max-width: 648px) {
  .tabs.vertical {
    grid-template-columns: 163px auto !important;
    gap: 1rem;
  }
}
@media (max-width: 410px) {
  .nav-tab {
    max-width: 146px;
  }
}
@media (max-width: 460px) {
  .formContatti .tabs-panel {
    width: 100%;
  }
}
@media (max-width: 390px) {
  .formContatti .tabs-panel {
    max-width: 90%;
    width: 100%;
  }
}
@media (max-width: 446px) {
  .nav-tab {
    max-width: 126px;
  }
  .tabs .tab-item {
    padding: 8px 4px;
    cursor: pointer;
    user-select: none;
    transition: border 0.3s ease-in-out;
    position: relative;
    bottom: -1px;
    text-transform: uppercase;
    font-size: 0.7rem;
    letter-spacing: 0.02rem;
  }
  .formContatti .tabs-panel {
    width: 100%;
  }
  .form-select,
  .form-control {
    font-size: 13px;
  }
  .tabs.vertical {
    grid-template-columns: 114px auto !important;
    gap: 0.5rem;
  }
}
@media (max-width: 320px) {
  .nav-tab {
    max-width: 132px;
  }
  .formContatti .tabs-panel {
    max-width: 204px;
    width: 100%;
  }
}
@media (max-width: 392px) {
  .tabs.vertical {
    grid-template-columns: 114px auto !important;
    gap: 0.5rem;
  }
}

.formContatti .form {
  max-width: 258px;
}
.tabs.vertical {
  max-width: 100%;
}
.tabs-panel {
  -bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
</style>
