<script setup lang="ts">
import HomeComponent from '@/components/HomeComponent.vue'
import HomeGallery from '@/components/HomeGallery.vue'
import HomeFormContatti from '@/components/HomeFormContatti.vue'
import HomeProdottiPromozioni from '@/components/HomeProdottiPromozioni.vue'
import HomeSportelli from '@/components/HomeSportelli.vue'
import ContactInformation from '@/components/ContactInformation.vue'
import GoogleMap from '@/components/GoogleMap.vue'
import { useCounterStore } from '@/stores/counter'
import { reactive } from 'vue'
import VLazyImage from 'v-lazy-image'
import imgAbruzzo from '../assets/abruzzo_image.webp'
import imgMarche from '../assets/marche_image.webp'
import imgSx from '../assets/impianto-fotovoltaico.webp'
import imgDx from '../assets/impianto-eolico.webp'
const store = useCounterStore()
const frontoffices = reactive(store.gettersfrontoffices)
</script>
<style lang="scss">
.googleMap {
  width: 100%;
  height: 500px;
}
.heading_border {
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 15px;
  padding: 5px 15px;
}
.info {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-left: 20px;
}
.service-list {
  width: 350px;
}
.service-list li:not(:last-child) {
  margin-bottom: 8px;
}
.section {
  padding-top: 100px;
}
.section-s {
  padding-top: 50px;
}
.colDxAboutUs {
  //background-color: rgb(255, 0, 0);
  background-color: rgb(255, 0, 98);
  width: 100%;
  height: 100%;
}
.colSxAboutUs {
  //background-color: #ffae00;
  background-color: rgb(0, 81, 255);
  width: 100%;
  height: 100%;
}
.aboutUsHome {
  margin: 7rem;
}
.servicesListHome {
  margin: 9rem;
}
@media (max-width: 991px) {
  .form-contatti-tabs {
  }
  .aboutUsHome {
    margin: 4rem;
  }
  .servicesListHome {
    margin: 0rem;
    margin-top: 2rem;
  }
}
@media (min-width: 1300px) {
  .img-home {
    width: auto;
    max-width: 600px;
  }
}
</style>
<template>
  <main>
    <HomeGallery />

    <HomeComponent id="aboutUs" class="container-fluid">
      <template #col-sx>
        <div class="aboutUsHome">
          <h2 class="pb-4">Chi Siamo</h2>
          <p>
            Nasce nel 2005 in Emilia Romagna come costola del primo gruppo italiano specializzato
            nella vendita indiretta, occupandosi come mission di portare nelle case degli italiani
            la nuova tecnologia 3G e quindi il nuovo modo di comunicare: “la videochiamata”.
          </p>
          <p>
            In breve Tempo Match è diventata Leader nel settore per L’Emilia Romagna fino a coprire
            anche l’intera regione Marche, con focus particolare nelle provincie di Ancona e Pesaro.
          </p>
          <p>
            In questo periodo l’azienda è stata corteggiata da tutti i partner nel campo delle
            telecomunicazioni oltre ai primi contatti con il settore Energy all’inizio del suo boom
            economico grazie alla liberalizzazione dei mercati.
          </p>
          <p>
            È stato solo dopo l’incontro con un partner commerciale importante: HeraComm con un
            ottimo progetto di sviluppo a lungo termine, adatto soprattutto alla forte crescita
            dell’azienda, che la stessa ha deciso di spostare l’attenzione sugli obbiettivi
            progettuali richiesti.
          </p>

          <router-link class="btn btn-primary" to="/aboutUs">Leggi Tutto</router-link>
        </div>
      </template>
      <template #col-dx>
        <div class="colDxAboutUs d-flex justify-content-center align-items-center">
          <img alt="img1" class="mx-auto d-block img-fluid img-home" :src="imgDx" />
        </div>
      </template>
    </HomeComponent>

    <HomeComponent id="services" class="container-fluid">
      <template #col-sx>
        <div class="colSxAboutUs d-flex justify-content-center align-items-center">
          <img alt="img2" class="mx-auto d-block img-fluid img-home" :src="imgSx" />
        </div>
      </template>
      <template #col-dx>
        <div class="servicesListHome">
          <h3 class="primary text-center">Servizi al cliente</h3>
          <div class="info">
            <ul class="service-list">
              <li>Proiezione spesa su consumi reali</li>
              <li>Allaccio Nuove Forniture elettriche e prime attivazioni Gas</li>
              <li>Analisi sprechi derivanti da esubero potenza su contatore</li>
              <li>Aggiornamento Offerte justify</li>
              <li>Riduzione Consumi mediante tecnologia LED e Termostati intelligenti</li>
              <li>Allaccio Nuove Linee Elettriche e posa Contatori</li>
            </ul>
          </div>
        </div>
      </template>
    </HomeComponent>

    <HomeProdottiPromozioni
      class="container-lg section container"
      id="products"
    ></HomeProdottiPromozioni>

    <HomeSportelli id="branches" class="container section"></HomeSportelli>

    <GoogleMap :frontoffices="frontoffices" class="container section-s"></GoogleMap>

    <HomeFormContatti id="contacts" class="container-lg section"></HomeFormContatti>

    <ContactInformation class="section-s"></ContactInformation>

    <HomeComponent class="section container">
      <template #col-sx>
        <div class="container">
          <div class="text-left">
            <div class="mt-2">
              <h4><span class="heading_border bg-warning">MARCHE</span></h4>
            </div>
          </div>
          <a href="sportelli_MARCHE"
            ><v-lazy-image
              :src="imgMarche"
              width="610"
              height="145"
              alt="image_12"
              class="img-fluid"
          /></a>
          <p>
            Le Marche è una delle meravigliose regioni del centro Italia. Purtroppo non ha la grande
            notorietà di molte altre, ma vi possiamo assicurare che non è assolutamente da
            sottovalutare, perché spesso non si coglie a pieno quanto le Marche abbia da offrire a
            livello naturalistico, storico e culinario. Se vi capiterà di inoltrarvi nel cuore di
            questa regione potrete scoprire un’infinità di curiosità, di “mondi” poco conosciuti e
            di posti che vi faranno innamorare del suo territorio. Dove poteva trovarsi il borgo più
            bello d’Italia se non nelle Marche? Tra i numerosissimi e bellissimi borghi presenti in
            questa regione c’è uno che spicca su tutti quanti, stiamo parlando di Gradara, una
            minuscola località che si trova in provincia di Pesaro Urbino. Nel 2018 alle Marche è
            stato assegnato il titolo di regione più Green d’Italia. Molto di questo merito è da
            conferire alle sue città che puntano molto sulla eco-sostenibilità.
          </p>
        </div>
      </template>

      <template #col-dx>
        <div class="container">
          <div class="text-left">
            <div class="mt-2">
              <h4><span class="heading_border bg-success">ABRUZZO</span></h4>
            </div>
          </div>
          <a href="sportelli_ABRUZZO"
            ><v-lazy-image
              :src="imgAbruzzo"
              width="610"
              height="145"
              alt="image_11"
              class="img-fluid"
          /></a>
          <p>
            50 anni fa l’Abruzzo era una regione unica col Molise e difatti il nome era ‘Regione di
            Abruzzi e Molise’, ma nel 1963 l3 cose sono cambiate decretando la nascita di due
            regioni differenti. Ma da dove nasce il nome ‘Abruzzo’? Sicuramente da molto lontano e
            precisamente da ‘Aprutium’ il nome della zona di Teramo durante il Medioevo e nel
            periodo seguente la realizzata dell’invasione Normanna. il Gran Sasso nasconde una
            piccola città sottorranea. Strano vero? Ma è proprio così! Difatti la galleria che
            oltrepassa il Gran Sasso da versante a versante, nasconde l’entrata di una città
            sotterranea. Si tratta del laboratorio nazionale di Fisica Nucleare. Un luogo
            incredibile realizzato intorno al 1988 in cui 750 studiosi conducono esperimenti e
            ricerche sull’origine dell’universo e a causa delle complessità di tali esperimenti e
            necessario isolarsi dall’ambiente circostante. Per queste ragioni che nasce il
            laboratorio italiano di fisica nucleare.
          </p>
        </div>
      </template>
    </HomeComponent>
  </main>
</template>
<style>
@media (max-width: 396px) {
  .tabs .tab-list__item {
    padding: 8px 7px;
    font-size: 0.75rem;
    letter-spacing: 0.03rem;
  }
}
@media (max-width: 376px) {
  .tabs .tab-list__item {
    padding: 8px 6px;
    font-size: 10.5px;
    letter-spacing: 0.02rem;
  }
}
</style>
