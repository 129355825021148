<script setup lang="ts">
import Layout from './layout.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

const loading = true
const color = '#003cff'
const size = '32px'
</script>

<template>
  <Suspense>
    <template #default>
      <Layout></Layout>
    </template>
    <template #fallback>
      <div style="height: 100vh" class="d-flex justify-content-center align-items-center">
        <div>
          <PulseLoader :loading="loading" :color="color" :size="size"></PulseLoader>
        </div>
      </div>
    </template>
  </Suspense>
</template>

<style lang="scss">
@import '@/assets/base.css';
@import url(https://fonts.googleapis.com/css?family=Sora);

.img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: auto;
  border: solid 1px #ccc;
}
body {
  font-family: 'Sora', sans-serif;
  //font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}
.containerTitle_ {
  height: 214px;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
}
.breadcrumb-text > h1 {
  font-size: 80px;
  text-transform: capitalize;
  line-height: 1;
  font-weight: 500;
  color: #112433;
  margin: 0;
}
@media (max-width: 549px) {
  .breadcrumb-text > h1 {
    font-size: 60px;
  }
  .containerTitle_ {
    height: 180px;
  }
}
</style>
