<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import posthog from 'posthog-js'
import PrincipalNavBar from '@/components/PrincipalNavBar.vue'
import SecondaryNavBar from '@/components/SecondaryNavBar.vue'
import { useCounterStore } from '@/stores/counter'

const FooterSection = defineAsyncComponent(() => import('@/components/FooterSection.vue'))
const CookieConsentVue = defineAsyncComponent(
  () => import('./components/CookieConsent/CookieConsent.vue')
)

await posthog.init('phc_8Yu5ylSHSjTDfM6VU9qITqj0gKqza17g6GLTiLlLv4Y', {
  api_host: 'https://eu.posthog.com'
})
const store = useCounterStore()
await store.getProducts()
await store.getLanguages()
await store.getFrontOffices()
await store.getMessageObjects()

const preferences = [
  {
    title: 'Funzionali',
    value: 'funzionali',
    isRequired: true,
    description:
      'Questi cookie sono necessari per le funzionalità di base del sito e sono sempre abilitati. Questi cookie ti consentono di essere ricordato mentre esplori il sito in una particolare sessione. I cookie funzionali ti aiutano ad accedere al sito e a completare in modo sicuro gli acquisti, oltre ad aiutarti in caso di problemi per la sicurezza e la conformità alle normative.'
  },
  {
    title: 'Analisi',
    value: 'analisi',
    description:
      "Vengono utilizzate soluzioni di analisi dei dati per identificare le interazioni del visitatore con il sito web, allo scopo di migliorare l'esperienza nel sito o per risolvere potenziali problemi con il sito"
  }
]

function onAccept(value) {
  posthog.opt_in_capturing()
}
function onSavePreferences(value) {
  posthog.opt_out_capturing()
}
</script>

<template>
  <SecondaryNavBar class="" />
  <PrincipalNavBar class="sticky-top bg-white" />
  <Suspense>
    <template #default> </template>
    <template #fallback> Loading... </template>
  </Suspense>
  <RouterView></RouterView>
  <Suspense>
    <template #default>
      <CookieConsentVue
        :preferences="preferences"
        @on-accept-all-cookies="onAccept"
        @on-save-cookie-preferences="onSavePreferences"
      ></CookieConsentVue>
    </template>
    <template #fallback> Loading... </template>
  </Suspense>
  <FooterSection />
</template>

<style lang="scss">
@import '@/assets/base.css';
@import url(https://fonts.googleapis.com/css?family=Sora);

.img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: auto;
  border: solid 1px #ccc;
}
body {
  font-family: 'Sora', sans-serif;
  //font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}
.containerTitle_ {
  height: 214px;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
}
.breadcrumb-text > h1 {
  font-size: 80px;
  text-transform: capitalize;
  line-height: 1;
  font-weight: 500;
  color: #112433;
  margin: 0;
}
@media (max-width: 549px) {
  .breadcrumb-text > h1 {
    font-size: 60px;
  }
  .containerTitle_ {
    height: 180px;
  }
}
</style>
