<template>
  <section>
    <div class="container d-flex align-content-center flex-wrap justify-content-center">
      <div class="row row-cols-1 row-cols-md-3 pt-3">
        <div class="col-md p-2 via d-flex justify-content-center">
          <div class="dbox w-100 text-center">
            <div class="shadow icon d-flex align-items-center justify-content-center">
              <font-awesome-icon icon="fa-solid fa-location-dot" class="iconFa" />
            </div>
            <div class="text">
              <p>
                <span> Via Alcide De Gasperi, 78/C, 60155, Ancona (AN)</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md p-2 phone d-flex justify-content-center">
          <div class="dbox w-100 text-center">
            <div class="shadow icon d-flex align-items-center justify-content-center">
              <font-awesome-icon icon="fa-solid fa-phone" class="iconFa" />
            </div>
            <div class="text">
              <p>
                <span />
                <a href="tel://+390712832386">+39 0712832386</a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md p-2 email d-flex justify-content-center">
          <div class="dbox w-100 text-center">
            <div class="shadow icon d-flex align-items-center justify-content-center">
              <font-awesome-icon icon="fa-solid fa-envelope" class="iconFa" />
            </div>
            <div class="text">
              <p>
                <span />
                <a href="mailto:info@matchancona.it">info@matchancona.it</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts"></script>
<style lang="scss">
.dbox {
  width: 240px !important;
}
.dbox .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgb(0, 81, 255);
  margin: 0 auto;
  margin-bottom: 20px;
}
.dbox .icon span {
  font-size: 20px;
  color: #fff;
}
.emailText {
  justify-content: center;
  display: flex;
}
.iconFa {
  color: #fff;
}
</style>
