<template>
  <section>
    <div class="row justify-content-center align-items-center">
      <div class="col-md-12">
        <Tabs :options="{ useUrlFragment: false }" position="center">
          <Tab v-for="product in products as any" :key="product.id" :name="product.name">
            <div class="text-center">
              <h4></h4>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-6">
                <img
                  alt="img product"
                  :src="urlServer + product.image_products[0].path + product.image_products[0].name"
                  class="img"
                />
              </div>
              <div class="col-md-6">
                <div class="mt-4">
                  <p v-html="product.description"></p>
                </div>
                <AccordionComponent
                  :accordionId="'accordion_d_' + product.id"
                  class=""
                  :alwaysopen="false"
                  classButton="accordion-button"
                  classContent="accordion-collapse collapse"
                  :accordionCollapseId="'collapseaccordion_d_' + product.id"
                >
                  <template v-slot:accordion-header>
                    <span>Specifiche</span>
                  </template>
                  <template v-slot:accordion-body>
                    <p v-html="product.specifics"></p>
                  </template>
                </AccordionComponent>
                <AccordionComponent
                  :accordionId="'accordion_c_' + product.id"
                  class=""
                  :alwaysopen="false"
                  classButton="accordion-button"
                  classContent="accordion-collapse collapse "
                  :accordionCollapseId="'collapseaccordion_c_' + product.id"
                >
                  <template v-slot:accordion-header>
                    <span>Contatti</span>
                  </template>
                  <template v-slot:accordion-body>
                    <p>Vuoi contattarci per avere informazioni?</p>
                    <a @click="select('contactUS')" role="button" class="btn btn-primary"
                      >Contattaci</a
                    >
                  </template>
                </AccordionComponent>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import AccordionComponent from './AccordionComponent.vue'
import { useCounterStore } from '@/stores/counter'
import { computed, getCurrentInstance } from 'vue'
import Tabs from '../components/Tab/TabsComponent.vue'
import Tab from '../components/Tab/TabComponent.vue'
import useEventsBus from '../utils/eventBus.js'

const store = useCounterStore()
const app: any = getCurrentInstance()

const urlServer = app.appContext.config.globalProperties.$urlServer

const { emit } = useEventsBus()

async function select(value: any) {
  await emit('selectTab', '#' + value)
  scrollToElement('contacts')
}

function scrollToElement(id: string) {
  const el = document.getElementById(id) as HTMLElement
  if (el) {
    el.scrollIntoView({
      behavior: 'smooth'
    })
  }
}

const products = computed(() => {
  return store.gettersProducts
})
</script>
<style lang="scss">
.accordion-button {
  padding: 10px;
}
.img {
  min-width: 250px;
  height: 100%;
}
.tabs .tab-list {
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.accordion-button {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.accordion-button:is(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
</style>
