<template>
  <GMapMap :center="center" :zoom="zoom" ref="googleMapRef" map-type-id="roadmap" class="googleMap">
    <GMapMarker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      :clickable="true"
      :draggable="false"
      @click="openMarker(m.id, index)"
    >
      <GMapInfoWindow
        :closeclick="true"
        @closeclick="closeMarker(null, index)"
        :opened="openedMarkerID[index] === m.id"
      >
        <div id="sportello" class="" style="max-width: 343px">
          <div class="pe-md-3" v-if="frontoffices[index].image_front_offices.length > 0">
            <GallerySportello
              :images="frontoffices[index].image_front_offices"
              class=""
            ></GallerySportello>
          </div>
          <div class="flex-grow-1">
            <div class="mt-1">
              <div class="text-primary title">
                {{ frontoffices[index].type + ' ' + frontoffices[index].name }}
              </div>
              <span style="margin-top: 15px">
                {{
                  frontoffices[index].street +
                  ' | ' +
                  frontoffices[index].city +
                  ' | ' +
                  frontoffices[index].cap
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <a target="_blank" rel="noopener noreferrer" :href="frontoffices[index].link_google">
            Vai a google map</a
          >
          <a
            target="_blank"
            rel="noopener noreferrer"
            :href="'/sportello/' + frontoffices[index].id"
          >
            Vai a sportello</a
          >
        </div>
      </GMapInfoWindow>
    </GMapMarker>
  </GMapMap>
</template>

<script setup>
import { ref, reactive } from 'vue'
import GallerySportello from '@/components/GallerySportello.vue'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
const props = defineProps({
  frontoffices: {
    type: Array || Object
  },
  center: {
    type: Object || Array,
    default: {
      lat: 43.60713419935518,
      lng: 13.50890471824696
    }
  },
  zoom: {
    type: Number,
    default: 7
  }
})

const center = reactive(props.center)
const zoom = reactive(props.zoom)
const locations = ref([])
const markers = ref([])
const openedMarkerID = ref([])
const frontoffices = ref(null)

if (props.frontoffices.constructor === Array) {
  frontoffices.value = reactive(props.frontoffices)
  frontoffices.value.forEach((element) => {
    markers.value.push({
      id: element.id,
      position: {
        lat: parseFloat(element.latitude),
        lng: parseFloat(element.longitude)
      }
    })
  })
} else {
  frontoffices.value = reactive([props.frontoffices])
  markers.value[0] = {
    id: frontoffices.value[0].id,
    position: {
      lat: parseFloat(frontoffices.value[0].latitude),
      lng: parseFloat(frontoffices.value[0].longitude)
    }
  }
}

const googleMapRef = ref(null)
const mapObject = ref(null)

const svgMarker = {
  path: faLocationDot.icon[4],
  fillColor: 'blue',
  fillOpacity: 1,
  strokeWeight: 1,
  rotation: 0,
  scale: 0.09,
  anchor: mapObject.value
}

let appINDEX = null

function openMarker(id, index) {
  closeMarker(null, appINDEX)
  openedMarkerID.value[index] = id
  appINDEX = index
}
function closeMarker(id, index) {
  openedMarkerID.value[index] = id
}
</script>
<style lang="scss">
.googleMap .img-wrapper {
  width: 91px;
  height: 60px;
}
.googleMap #gal1 img {
  width: 42px;
  float: left;
  padding-top: 2px;
  padding-left: 0;
}
.googleMap #gallery-sportello {
  width: 186px;
}
.gm-style .gm-style-iw {
  font-weight: 300;
  font-size: 11px;
  overflow: hidden;
}
#sportello {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}
.title {
  font-size: 16px;
}
@media (min-width: 480px) {
  .gm-style .gm-style-iw {
    font-weight: 300;
    font-size: 12px;
    overflow: hidden;
  }
  #sportello {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .title {
    font-size: 16px;
  }
}
</style>
