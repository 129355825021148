<template>
  <nav class="navbar navbar-expand-xl navbar-light border-bottom">
    <div class="container-fluid">
      <router-link to="/" class="navbar-brand">
        <img src="./../assets/logo-Match.webp" width="360" class="img-fluid" alt="logo"
      /></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="ms-auto navbar-nav align-items-center">
          <li class="nav-item">
            <router-link :to="{ name: 'home' }" class="nav-link active" aria-current="page"
              >Home
            </router-link>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="scrollToElement.scrollToElement('aboutUs', '')"
              role="button"
            >
              Chi siamo
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="scrollToElement.scrollToElement('services', '')"
              role="button"
            >
              Servizi
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="scrollToElement.scrollToElement('products', '')"
              role="button"
            >
              Prodotti
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="scrollToElement.scrollToElement('branches', '')"
              role="button"
            >
              Sportelli
            </a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#products"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Contatti
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <a
                  @click="scrollToElement.scrollToElement('contactUS', 'tab')"
                  role="button"
                  class="dropdown-item"
                  >Contattaci</a
                >
              </li>
              <li>
                <a
                  @click="scrollToElement.scrollToElement('workWithUs', 'tab')"
                  role="button"
                  class="dropdown-item"
                  >Lavora con noi</a
                >
              </li>
              <li>
                <a
                  @click="scrollToElement.scrollToElement('estateAgency', 'tab')"
                  role="button"
                  class="dropdown-item"
                  >Agenzia immobiliare</a
                >
              </li>
              <li>
                <a
                  @click="scrollToElement.scrollToElement('selfReading', 'tab')"
                  role="button"
                  class="dropdown-item"
                  >Autolettura</a
                >
              </li>
              <li>
                <a
                  @click="scrollToElement.scrollToElement('appointment', 'tab')"
                  role="button"
                  class="dropdown-item"
                  >Appuntamento</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="navbar-brand" href="https://www.matchancona.it">
              <img
                src="./../assets/agenzia_heracomm.webp"
                alt="logo"
                class="h-auto"
                style="width: 200px"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script setup lang="ts">
import useScrollToElement from '../utils/scrollToElement'

const scrollToElement = useScrollToElement()
</script>
<style scoped>
.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0px;
}
header {
  background-color: white;
}
.nav .nav-link,
.nav-item {
  color: rgba(0, 0, 0, 0.664);
}
@media (min-width: 1200px) {
  .navbar-expand-lg .nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
.navbar .nav-link {
  font-size: 1rem;
  font-weight: bold;
}
.nav .nav-link {
  transition: all 0.2s ease-in-out;
  outline: 0;
}
.nav {
  font-family: Arimo, sans-serif;
  letter-spacing: 0.05rem;
  font-weight: 700;
  line-height: 1.5;
  font-size: 1.1rem;
}
.navbar .navbar-nav .nav-link {
  margin-right: 0;
  padding: 4px 0;
  color: var(--dark);
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  outline: none;
}
@media (min-width: 900px) {
  .navbar .navbar-nav .nav-link {
    margin-right: 30px;
    padding: 6px 0;
    color: var(--dark);
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    outline: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-lg .nav .nav-link:before {
    transition: all 0.2s ease-in-out;
    position: absolute;
    content: '';
    height: 0.1875rem;
    left: 1.25rem;
    right: 1.25rem;
    bottom: 0;
    background: 0 0;
  }
  .navbar-expand-xl .nav .nav-link:before {
    transition: all 0.2s ease-in-out;
    position: absolute;
    content: '';
    height: 0.1875rem;
    left: 1.25rem;
    right: 1.25rem;
    bottom: 0;
    background: 0 0;
  }
  .navbar .navbar-nav .nav-link {
    margin-right: 30px;
    padding: 25px 0;
    color: var(--dark);
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    outline: none;
  }
}
@media (max-width: 772px) {
  .nav {
    font-size: 1rem;
  }
}
.nav-item:hover,
.nav-item > a:hover {
  color: black !important;
}
.gm-ui-hover-effect {
  opacity: 0.6;
}
.gm-ui-hover-effect:hover {
  opacity: 1;
}
#header_ {
  box-shadow: 0 0 0.625rem rgb(0 0 0 / 10%);
}
@media (min-width: 1200px) {
  body.page-scrolled #header_ {
    box-shadow: 0 0 0.625rem rgb(0 0 0 / 10%);
  }
}
</style>
